import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "enhanced-sharing-for-confluence"
    }}>{`Enhanced Sharing for Confluence`}</h1>
    <br />
    <h2 {...{
      "id": "sharing-functionality"
    }}>{`Sharing Functionality`}</h2>
    <p>{`Every time you use the Confluence sharing dialog, the App will capture what your share and who you shared it with.
This way, you will not lose track of what you have shared like you would normally with Confluence.`}</p>
    <Image width={'22em'} src='/public/sharing.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`To find the pages you have shared, open your user profile menu and look for "Shared Pages". `}</p>
    <Image width={'22em'} src='/public/menu.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`From here, users can find all pages that they have shared with others.`}</p>
    <Image width={'100%'} src='/public/usershares.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <h2 {...{
      "id": "read-confirmations"
    }}>{`Read Confirmations`}</h2>
    <p>{`When read confirmations are activated (see admin guide), you can find a special share button at the top of the page.`}</p>
    <Image width={'35em'} src='/public/sharebutton.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`You can then send a read confirmation to individual users. When you mark the checkbox, the user will be prompted with a
confirmation dialog when he visits the page. Otherwise, the read confirmation will be sent automatically.`}</p>
    <Image width={'40em'} src='/public/dialog.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />
    <p>{`You can see the status of your read confirmations in the shared pages list. Confirmed reads will be marked with a green indicator.`}</p>
    <Image width={'50em'} src='/public/confirmation.png' sx={{
      borderRadius: 8,
      border: '1px solid lightgrey',
      mb: 5
    }} mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      